import './index.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Usersystem from "./pages/Usersystem";
import Finetuning from "./pages/Finetuning";
import Layout from "./Components/Layout";
import Login from "./pages/Login";
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route
          element={<Layout />}
        >
          <Route path="/user" element={<Usersystem />} />
          <Route path="/fine-tuning" element={<Finetuning />} />
        </Route>

        {/* <Route path="*" element={<NoPage />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);