import React, {useState, useEffect} from 'react';
import { PlusIcon } from '@heroicons/react/16/solid';
import UserTable from '../Components/UserSystem/UserTable';
import UserModal from '../Components/UserSystem/UserModal';
import { getAllUsers, deleteUser, deleteMultipleUsers } from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from '../Components/Modals/DeleteModal';
import UserEditModal from '../Components/UserSystem/UserEditModal';

const Usersystem = () => {

    const getAllUsersHandler = async () => {
        let response = await getAllUsers();
        if (!response)
        {
            console.log("Error getting all users")
            return
        }
        setAllUsers(response);
    }

    useEffect(() => {
        getAllUsersHandler();
    }, [])

    const deleteAllUserHandler = async (checkedUsers) => {
        if (checkedUsers.length > 0)
            {
            let response = await deleteMultipleUsers(checkedUsers);
            if (!response.valid)
            {
                toast.error("Error deleting users")
                return
            }
            toast.success("Selected Users deleted successfully")
            setCheckedUsers([])
            handleDeleteAllClose()
            getAllUsersHandler();
        }
        else
        {
            toast.error("No users selected")
        }
    }

    const deleteUserHandler = async (id) => {
        let response = await deleteUser(id);
        if (!response.valid)
        {
            toast.error("Error deleting user")
            return
        }
        toast.success("User deleted successfully")
        handleDeleteClose()
        getAllUsersHandler();
    }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [editOpen, setEditOpen] = useState(false);
    const handleEditOpen = () => setEditOpen(true);
    const handleEditClose = () => setEditOpen(false);

    const [deleteOpen, setDeleteOpen] = useState(false);
    const handleDeleteOpen = () => setDeleteOpen(true);
    const handleDeleteClose = () => setDeleteOpen(false);

    const [deleteAllOpen, setDeleteAllOpen] = useState(false);
    const handleDeleteAllOpen = () => setDeleteAllOpen(true);
    const handleDeleteAllClose = () => setDeleteAllOpen(false);
    
    const [allUsers, setAllUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});

    const [searchBar, setSearchBar] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage] = useState(5);

    const filteredUsers = allUsers.filter((user) => {
        if (searchBar === "") return true;
        return (
            user.first_name.toLowerCase().includes(searchBar.toLowerCase()) ||
            user.last_name.toLowerCase().includes(searchBar.toLowerCase()) ||
            user.email.toLowerCase().includes(searchBar.toLowerCase())
        );
    });

    const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

    const paginatedUsers = filteredUsers.slice(
        (page - 1) * rowsPerPage,
        page * rowsPerPage
    );

    useEffect(() => {
        setPage(1);
    }, [searchBar]);

    const [checkedUsers, setCheckedUsers] = useState([]);

    return (
        <div className='lg:px-24 px-5 w-full flex flex-col'>
            {/* Icon */}
            <div onClick={handleOpen} className='border w-fit text-white rounded-full shadow-md bg-[#a157a1] cursor-pointer p-1'>
                <PlusIcon className='w-10 h-10'/>
            </div>
            {/* Table */}
            <div className='mt-4'>
                <UserTable users={allUsers} handleDeleteOpen={handleDeleteOpen} handleEditOpen={handleEditOpen} setSelectedUser={setSelectedUser} searchBar={searchBar} setSearchBar={setSearchBar} page={page} setPage={setPage} totalPages={totalPages} paginatedUsers={paginatedUsers} handleDeleteAllOpen={handleDeleteAllOpen} checkedUsers={checkedUsers} setCheckedUsers={setCheckedUsers}/>
            </div>
            <UserModal open={open} handleClose={handleClose} getAllUsersHandler={getAllUsersHandler}/>
            <UserEditModal open={editOpen} handleClose={handleEditClose} getAllUsersHandler={getAllUsersHandler} selectedUser={selectedUser}/>
            <DeleteModal 
            open={deleteOpen} 
            handleClose={handleDeleteClose} 
            text={"Are you sure to delete this user?"} 
            successFunction={() => {
                deleteUserHandler(selectedUser.id)
            }} />
            <DeleteModal 
            open={deleteAllOpen} 
            handleClose={handleDeleteAllClose} 
            text={checkedUsers.length > 0 ? `Are you sure to delete these ${checkedUsers.length} users?` : `No users to delete`} 
            successFunction={() => {
                deleteAllUserHandler(checkedUsers)
            }} />
            <ToastContainer />
        </div>
    );
};
  
  export default Usersystem;