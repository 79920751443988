import React, { forwardRef } from 'react'
import gpt from '../../assets/chatgpt-icon.svg'
function RecieveMessage({ message }, ref) {
  return (
      <div ref={ref} className="flex lg:w-1/2 w-full relative self-start space-x-4" >
          <img src={gpt} className=" rounded-full flex self-end right-0 lg:w-12 lg:h-12 w-8 h-8  -bottom-5 "/>
          <div className="bg-gray-200 w-full text-black  flex  self-start lg:p-4 p-2 lg:rounded-2xl rounded-lg relative">
            <div
              style={{
                content: "''",
                position: "absolute",
                bottom: "0px",
                left: "-20px", // Adjusts the position of the triangle
                width: "0",
                height: "0",
                borderRight: "35px solid #e5e7eb", // Same color as the bubble
                borderTop: "13px solid transparent", // Makes the triangle pointy
              }}
            />
            <p className='break-words '>{message}</p>
          </div>
        </div>
      );
    }
export default forwardRef(RecieveMessage);