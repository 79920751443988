import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LightBulbIcon, UsersIcon, Bars3Icon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/solid';
import Logo from '../assets/nube_logo.png';
import { Outlet } from 'react-router-dom';
import { isUserValidated } from '../api/api';

const Layout = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const userValidationHandler = async () => {
        const response = await isUserValidated(localStorage.getItem('token'));
        if (!response) {
            navigate('/');
        } else if (location.pathname === '/user' && localStorage.getItem('role').toLowerCase() === 'operator') {
            navigate('/');
        }
    };

    useEffect(() => {
        userValidationHandler();
    }, [location.pathname]);

    return (
        <div>
            {/* Navbar */}
            <nav className="fixed top-0 z-50 w-full bg-[#EAEAEA] border-b border-gray-200">
                <div className="h-20 flex items-center">
                    <div className="w-full flex items-center justify-between px-4">
                        {/* Menu button for mobile */}
                        <div className="flex items-center justify-start">
                            <button 
                                className="lg:hidden w-10 h-10 border bg-black text-white flex justify-center items-center"
                                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            >
                                <Bars3Icon className='w-6 h-6' />
                            </button>
                            <div className='ms-3'>
                                <img src={Logo} alt="Nube Lab" className='w-32 h-16' />
                            </div>
                        </div>

                        <div className="flex items-center text-center gap-x-4">
                            <div>
                                <h1 className='text-base font-semibold'>
                                    {localStorage.getItem('first_name')} {localStorage.getItem('last_name')}
                                </h1>
                                <h6 className='text-xs font-normal text-[#808080]'>
                                    {localStorage.getItem('role')}
                                </h6>
                            </div>
                            <div>
                                <img className="w-12 h-12 rounded-full" src={localStorage.getItem('image') !== 'null' ? localStorage.getItem('image') : `https://ntrepidcorp.com/wp-content/uploads/2016/06/team-1.jpg`} alt="user photo" />
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Sidebar */}
            <aside className={`fixed top-0 left-0 z-40 w-28 h-screen bg-[#EAEAEA] border-r border-gray-200 transition-transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} lg:translate-x-0`} aria-label="Sidebar">
                <div className="h-full px-3 pt-24 pb-4 overflow-y-auto bg-[#EAEAEA] flex flex-col justify-between">
                    <ul className="space-y-8 font-medium">
                        <li>
                            <Link to='/fine-tuning' className='w-full flex justify-center'>
                                <div className={`border p-2 rounded ${location.pathname === '/fine-tuning' ? 'bg-[#FEFEFE]' : ''}`}>
                                    <LightBulbIcon className='w-8 h-8' />
                                </div>
                            </Link>
                        </li>
                        {
                            localStorage.getItem('role').toLowerCase() === 'administrator' &&
                            <li>
                                <Link to='/user' className='w-full flex justify-center'>
                                    <div className={`border p-2 rounded ${location.pathname === '/user' ? 'bg-[#FEFEFE]' : ''}`}>
                                        <UsersIcon className='w-8 h-8' />
                                    </div>
                                </Link>
                            </li>
                        }
                    </ul>
                    <div>
                        <div
                            onClick={() => {
                                localStorage.removeItem('token');
                                navigate('/');
                            }}
                            className='w-full flex justify-center cursor-pointer'
                        >
                            <div className='p-2'>
                                <ArrowLeftOnRectangleIcon className='w-8 h-8' />
                            </div>
                        </div>
                    </div>
                </div>
            </aside>

            {/* Content area */}
            <div className="lg:pl-32 pt-28">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;
