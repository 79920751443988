import React, { forwardRef } from "react";
import avatar from "../../assets/avatar-large.png";
function SendMessage({ message }, ref) {
  return (
    <div ref={ref} className="flex  lg:w-1/2 w-full relative self-end space-x-2" >
      <div className="relative bg-[#318CE7] lg:w-5/6 w-full text-white flex lg:p-4 p-2 lg:rounded-2xl rounded-lg">
        <p>{message}</p>
        <div
          style={{
            content: "''",
            position: "absolute",
            bottom: "0px",
            right: "-20px", // Adjusts the position of the triangle
            width: "0",
            height: "0",
            borderLeft: "35px solid #318CE7", // Same color as the bubble
            borderTop: "13px solid transparent", // Makes the triangle pointy
          }}
        />
      </div>
      <img src={avatar} className=" flex self-end right-0 lg:w-12 lg:h-12 w-8 h-8 -bottom-5 "/>
    </div>
  );
}

export default forwardRef(SendMessage);
