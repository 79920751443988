
export const englishToItalian = (text) => {
    if (text === 'Operator')
    {
        return 'Operatore';
    }
    else if (text === 'Administrator')
    {
        return 'Amministratore';
    }
}

export const italianToEnglish = (text) => {
    if (text === 'Operatore')
    {
        return 'Operator';
    }
    else if (text === 'Amministratore')
    {
        return 'Administrator';
    }
}