import React, { useState } from "react";
import { TrashIcon } from "@heroicons/react/16/solid";
import UserRow from "./UserRow";
import { Pagination, Stack } from "@mui/material";

const UserTable = ({ users, handleDeleteOpen, setSelectedUser, handleEditOpen, searchBar, setSearchBar, page, setPage, totalPages, paginatedUsers, checkedUsers, setCheckedUsers, handleDeleteAllOpen }) => {

    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        if (e.target.checked) {
            setCheckedUsers(users.map(user => user.id));
        } else {
            setCheckedUsers([]);
        }
    };

    return (
    <div className="flex flex-col">
        <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
            <div className="border rounded-lg divide-y divide-gray-200">
                <div className="py-4 px-4 flex justify-between items-center">
                    <div>
                        <h1 className="font-semibold">
                            Utenti di sistema
                        </h1>
                    </div>
                    <div className="flex items-center gap-x-2">
                        <div className="relative">
                            <input  value={searchBar} onChange={(e) => {setSearchBar(e.target.value)}} type="text" name="hs-table-search" id="hs-table-search" className="py-2 px-3 ps-9 block w-full border border-[#000000] shadow-sm rounded-2xl text-sm disabled:pointer-events-none" placeholder="Ricerca" />
                            <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                            <svg className="size-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <circle cx="11" cy="11" r="8"></circle>
                                <path d="m21 21-4.3-4.3"></path>
                            </svg>
                            </div>
                        </div>
                        <div onClick={() => {handleDeleteAllOpen()}} className="p-2 bg-[#EAEAEA] rounded-xl cursor-pointer">
                            <TrashIcon className="w-6 h-6" />
                        </div>
                    </div>
                </div>
                <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="py-3 px-4 pe-0">
                        <div className="flex items-center h-5">
                            <input 
                                id="hs-table-search-checkbox-all" 
                                type="checkbox" 
                                className="border-gray-200 rounded text-blue-600 focus:ring-blue-500" 
                                checked={selectAll}
                                onChange={handleSelectAll}    
                            />
                            <label for="hs-table-search-checkbox-all" className="sr-only">Checkbox</label>
                        </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase"></th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Nome</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Cognome</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Email</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase">Ruolo</th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase"></th>
                    </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {
                            paginatedUsers?.map((user) => (
                                <UserRow key={user.id} user={user} handleDeleteOpen={handleDeleteOpen} setSelectedUser={setSelectedUser} handleEditOpen={handleEditOpen} checkedUsers={checkedUsers} setCheckedUsers={setCheckedUsers}/>
                            ))
                        }
                    </tbody>
                </table>
                </div>

                <div className="py-8 flex justify-center">
                    <Stack spacing={2}>
                        <Pagination 
                        sx={{
                            '& .MuiPaginationItem-root': {
                              color: '#000000',
                              backgroundColor: '#F6F6F6',
                              borderRadius: "8px",
                              fontSize: "12px", 
                              height: "36px",
                              width: "50px",
                              '&.Mui-selected': {
                                backgroundColor: '#434343', 
                                color: 'white',
                              },
                              '&:hover': {
                                backgroundColor: '#7b7b7b',
                              },
                            },
                            '& .MuiPaginationItem-ellipsis': {
                              fontSize: '18px', 
                              width: '50px',
                            },
                          }}
                          count={totalPages}
                          page={page}
                          onChange={(event, value) => setPage(value)}
                          variant="outlined" shape="rounded" size="large" hidePrevButton hideNextButton />
                    </Stack>
                </div>
            </div>
            </div>
        </div>
    </div>
    );
}

export default UserTable;