import React, { useRef, useState, useEffect } from "react";
import SendMessage from "../Components/Finetuning/sendMessage";
import RecieveMessage from "../Components/Finetuning/recieveMessage";
import { PaperAirplaneIcon } from "@heroicons/react/16/solid";
import { PaperClipIcon } from "@heroicons/react/16/solid";
import { getAllChats, createChat } from "../api/api";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../Components/Loader";

const Finetuning = () => {
  const [activetab, setactivetab] = useState(0);
  const fileRef = useRef(null);
  const lastMessageRef = useRef(null);

  const categories = ['about', 'portfolio', 'blog', 'contact']
  const [allChats, setAllChats] = useState([])
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const [responseInProgress, setResponseInProgress] = useState(false);

  const createChatHandler = async () => {

    setResponseInProgress(true);
    
    let userResponse = {
      message: message,
      sender: "user",
    }
    
    setAllChats((prevChats) => [...prevChats, userResponse]);

    const formData = new FormData();
    formData.append('message', message);
    formData.append('category', categories[activetab]);

    let response = await createChat(formData);
    if (!response.valid) {
      response?.data?.then((res) => {
        console.log(res.details);
        toast.error(res.error);
      })
      return;
    }

    response?.data?.then((res) => {
      let aiResponse = {
        message: res.response,
        sender: res.sender,
      }
      setAllChats((prevChats) => [...prevChats, aiResponse]);
      setMessage("");
      setResponseInProgress(false);
      setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500)
    })
  }

  const getAllChatsHandler = async () => {
    setLoading(true)
    let response = await getAllChats(categories[activetab]);
    if (!response.valid) {
      response?.data?.then((res) => {
        console.log(res.details);
        toast.error(res.error);
      })
      return;
    } 

    response?.data?.then((res) => {
      setLoading(false)
      setAllChats(res)   
      setTimeout(() => {
        if (lastMessageRef.current) {
          lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }   
      }, 500)
    })
  }

  useEffect(() => {
    getAllChatsHandler();
  }, [activetab])

  return (
    <div className="lg:mx-32 mx-5  border-2 pb-10 rounded-xl space-y-6">
      <p className=" mt-4 ml-5 font-semibold">Fine Tuning</p>
      <div className=" flex items-center justify-center md:space-x-10  max-md:flex-col max-md:space-y-2">
        <button
          onClick={() => setactivetab(0)}
          className={` ${
            activetab !== 0
              ? "bg-gray-200 text-black"
              : "bg-[#318CE7] text-white"
          } w-40 rounded-3xl px-8 font-medium py-2 hover:bg-[#318CE7] hover:text-white`}
        >
          About us
        </button>
        <button
          onClick={() => setactivetab(1)}
          className={` ${
            activetab !== 1
              ? "bg-gray-200 text-black"
              : "bg-[#318CE7] text-white"
          } w-40 rounded-3xl px-8 font-medium py-2 hover:bg-[#318CE7] hover:text-white`}
        >
          Portfolio
        </button>
        <button
          onClick={() => setactivetab(2)}
          className={` ${
            activetab !== 2
              ? "bg-gray-200 text-black"
              : "bg-[#318CE7] text-white"
          } w-40 rounded-3xl px-8 font-medium py-2 hover:bg-[#318CE7] hover:text-white`}
        >
          Blog
        </button>
        <button
          onClick={() => setactivetab(3)}
          className={` ${
            activetab !== 3
              ? "bg-gray-200 text-black"
              : "bg-[#318CE7] text-white"
          } w-40 rounded-3xl px-8 font-medium py-2 hover:bg-[#318CE7] hover:text-white`}
        >
          Contacts
        </button>
      </div>
      <div className="justify-center flex items-center flex-col overflow-auto">
        <div className=" border-2 lg:w-4/6  w-3/4 items-center rounded-t-xl lg:p-10 p-4 flex flex-col space-y-10 h-80 overflow-y-auto">
        {
          loading && <Loader />
        }
        {
          allChats?.map((obj, index) => (
            obj?.sender === "user" ?
            <SendMessage key={index} message={obj?.message} ref={index === allChats.length - 1 ? lastMessageRef : null} />
            :
            <RecieveMessage key={index} message={obj?.message} ref={index === allChats.length - 1 ? lastMessageRef : null} />
          ))
        }
        </div>
        <div className=" lg:space-x-4 items-center  border-b-2 lg:p-5 p-2 border-x-2 lg:w-4/6  w-3/4  justify-center  flex  rounded-b-xl">
          <div className="border-2 h-14  border-gray-400 rounded-lg flex w-full py-[10px] lg:px-4   items-center lg:space-x-4 space-x-2">
            <input
              type="file"
              className="hidden"
              accept="image/*, video/*,application/*"
              ref={fileRef}
              onChange={() => {}}
            />
            {/*<PaperClipIcon
              className="w-6 h-6 text-gray-400 hover:cursor-pointer"
              onClick={() => {
                fileRef.current.click();
              }}
            ></PaperClipIcon>*/}
            <input placeholder="Type a message" value={message} onChange={(e) => {setMessage(e.target.value)}} className="h-10 w-full outline-none border-0"></input>
          </div>
          <PaperAirplaneIcon onClick={message.length > 0 && !responseInProgress ? createChatHandler : null} className={`w-7 h-7 ${message.length > 0 && !responseInProgress ? 'text-[#318CE7] cursor-pointer' : 'text-gray-400 cursor-not-allowed opacity-50'}`}></PaperAirplaneIcon>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Finetuning;
