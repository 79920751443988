import React, { useState } from 'react';
import { login } from '../api/api';
import { ToastContainer, toast } from 'react-toastify';
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/16/solid";
import { useNavigate } from 'react-router-dom';

const Login = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (email.length > 0 && password.length > 0)
        {
            const response = await login(email, password);
            if (!response.valid)
            {
                toast.error('Invalid Credentials')
                return
            }
    
            response.data.then((data) => {
                toast.success(data?.message);
                localStorage.setItem('token', data?.token);
                localStorage.setItem('email', data?.user.email);
                localStorage.setItem('first_name', data?.user.first_name);
                localStorage.setItem('last_name', data?.user.last_name);
                localStorage.setItem('role', data?.user.role);
                localStorage.setItem('image', data?.user.image_url);
                navigate('/fine-tuning')
            })
        }
        else
        {
            toast.error('Please fill in all fields')
        }
    }

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div class="h-screen md:flex">
                <div
                    class="relative overflow-hidden md:flex w-1/2 bg-gradient-to-tr from-blue-800 to-purple-700 i justify-around items-center hidden">
                    <div>
                        <h1 class="text-white font-bold text-4xl font-sans">NUBE</h1>
                        <p class="text-white mt-1">CREARE, INNOVARE, DOMINARE</p>
                    </div>
                    <div class="absolute -bottom-32 -left-40 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                    <div class="absolute -bottom-40 -left-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                    <div class="absolute -top-40 -right-0 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                    <div class="absolute -top-20 -right-20 w-80 h-80 border-4 rounded-full border-opacity-30 border-t-8"></div>
                </div>
                <div class="flex md:w-1/2 justify-center py-10 items-center bg-white">
                    <div class="bg-white">
                        <p class="text-gray-800 font-bold text-2xl text-center mb-7">Welcome Back</p>
                                <div class="flex items-center border-2 py-2 px-3 rounded-2xl mb-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none"
                                        viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                                    </svg>
                                    <input value={email} onChange={(e) => {setEmail(e.target.value)}} class="pl-2 outline-none border-none" type="email" name="" id="" placeholder="Email Address" />
                                </div>
                                <div class="flex items-center border-2 py-2 px-3 rounded-2xl">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fill-rule="evenodd"
                                            d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <input value={password} onChange={(e) => {setPassword(e.target.value)}} class="pl-2 outline-none border-none" type={showPassword ? 'text' : 'password'} name="" id="" placeholder="Password" />
                                    <span
                                        className="flex items-center cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {
                                            showPassword ?
                                            <EyeSlashIcon className="w-6 h-6 text-gray-600" />
                                            :
                                            <EyeIcon className="w-6 h-6 text-gray-600" />
                                        }
                                    </span>
                                </div>
                            <button onClick={handleSubmit} type="button" class="block w-full bg-indigo-600 mt-4 py-2 rounded-2xl text-white font-semibold mb-2">Login</button>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    );
}

export default Login;