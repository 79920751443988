import React, { useState, useRef } from "react";
import { Modal, Box } from "@mui/material";
import { createUser } from "../../api/api";
import { toast } from 'react-toastify';
import { italianToEnglish } from "../../helpers/helpers";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/16/solid";

const UserModal = ({ open, handleClose, getAllUsersHandler }) => {

    const fileInputRef = useRef(null);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "70%", // Reduced width for mobile
        minWidth: "330px",
        maxHeight: "90vh", // Limit height to 90% of the viewport height
        borderRadius: '10px',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2, // Adjust padding for smaller screens
        border: 'none',
        outline: 'none',
        overflowY: 'auto', // Enable vertical scrolling if needed
    };


    const [user, setUser] = useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        role: "Operatore",
        image: null,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    const [imagePreview, setImagePreview] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setUser({ ...user, image: e.target.files[0] });
        toast.success("Image Selected Successfully");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('first_name', user.firstName);
        formData.append('last_name', user.lastName);
        formData.append('email', user.email);
        formData.append('role', italianToEnglish(user.role));
        formData.append('password', user.password)
        if (user.image && user.image !== null) {
            formData.append('image', user.image);
        }

        let response = await createUser(formData) 
        if (!response.valid) {
            response.data.then((error) => {
                toast.error(error.message);
                Object.keys(error.errors).forEach((key) => {
                    error.errors[key].forEach((errorMessage) => {
                        toast.error(errorMessage);
                    });
                });
            });
            return;
        } else {
            toast.success("Utente aggiunto con successo!");
            handleCancel();
            getAllUsersHandler()
        }
    };

    const handleCancel = () => {
        setUser({
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            role: "Operatore",
            image: null,
        });
        setImagePreview(null);
        handleClose();
    };

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <div className="w-full flex h-full justify-center overflow-y-auto">
                    <form className="w-full max-w-full h-full bg-white p-4 overflow-y-auto">
                        {/* Outer Area */}
                        <div className="flex flex-col space-y-4 overflow-y-auto">
                            {/* Heading Area */}
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-lg font-medium">Aggiungi un nuovo utente</h2>
                                <svg onClick={handleCancel} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                </svg>
                            </div>

                            {/* Form Area */}
                            <div className="flex justify-between items-start max-lg:flex-col space-y-4 lg:space-y-0 lg:space-x-4">
                                {/* Form inputs */}
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 w-full">
                                    <div className="mb-4">
                                        <label className="block text-gray-700 font-bold mb-2" htmlFor="nome">
                                            Nome *
                                        </label>
                                        <input
                                            className="w-full p-2 border border-gray-300 rounded"
                                            type="text"
                                            name="firstName"
                                            value={user.firstName}
                                            onChange={handleChange}
                                            placeholder="Simone"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            className="block text-gray-700 font-bold mb-2"
                                            htmlFor="cognome"
                                        >
                                            Cognome *
                                        </label>
                                        <input
                                            className="w-full p-2 border border-gray-300 rounded"
                                            type="text"
                                            name="lastName"
                                            value={user.lastName}
                                            onChange={handleChange}
                                            placeholder="Mariani"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 font-bold mb-2" htmlFor="email">
                                            Email *
                                        </label>
                                        <input
                                            className="w-full p-2 border border-gray-300 rounded"
                                            type="email"
                                            name="email"
                                            value={user.email}
                                            onChange={handleChange}
                                            placeholder="email@address.com"
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <label
                                            className="block text-gray-700 font-bold mb-2"
                                            htmlFor="password"
                                        >
                                            Password *
                                        </label>
                                        <div className="relative">
                                            <input
                                                className="w-full p-2 border border-gray-300 rounded pr-10"
                                                type={showPassword ? 'text' : 'password'}
                                                name="password"
                                                value={user.password}
                                                onChange={handleChange}
                                                placeholder="pswd24$"
                                            />
                                            <span
                                                className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {
                                                    showPassword ? 
                                                    <EyeSlashIcon className="w-6 h-6 text-gray-600" />
                                                    : 
                                                    <EyeIcon className="w-6 h-6 text-gray-600" />
                                                }
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-gray-700 font-bold mb-2" htmlFor="ruolo">
                                            Ruolo
                                        </label>
                                        <select
                                            className="w-full p-2 border border-gray-300 rounded"
                                            name="role"
                                            value={user.role}
                                            onChange={handleChange}
                                        >
                                            <option value="Operatore">Operatore</option>
                                            <option value="Amministratore">Amministratore</option>
                                        </select>
                                    </div>
                                </div>
                                <div className=" max-lg:hidden">
                                    <div className="w-px h-80 bg-gray-300">
                        
                                    </div>
                                </div>
                                {/* Image uploader */}
                                <div className=" max-lg:w-full w-1/3  mb-4 flex flex-col gap-y-4 justify-center items-center">
                                    <label className="block text-gray-700 font-bold">
                                        Immagine profilo
                                    </label>
                                    <div className="flex items-center justify-center w-full border p-4 bg-gray-100 rounded-lg">
                                        <label className="flex flex-col items-center justify-center w-full p-4 text-gray-700">
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className="hidden"
                                                onChange={handleImageChange}
                                                ref={fileInputRef}
                                            />
                                            {imagePreview ? (
                                                <img
                                                src={imagePreview}
                                                alt="Uploaded Preview"
                                                className="w-48 h-48 object-cover rounded-full"
                                                />
                                            ) : (
                                                <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-20 h-20 text-gray-500"
                                                >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                                                />
                                                </svg>
                                            )}
                                        </label>
                                    </div>
                                    <div>
                                        <button
                                            onClick={() => {fileInputRef.current.click()}}
                                            type="button"
                                            className="px-16 py-2 bg-gray-500 text-white rounded"
                                        >
                                            Scegli immagine 
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Action Buttons */}
                            <div className="flex lg:justify-start justify-center gap-4 mt-4">
                                <button
                                    type="button"
                                    onClick={handleCancel}
                                    className="bg-gray-200 hover:bg-gray-300 text-gray-700 p-2 rounded"
                                >
                                    Annulla
                                </button>
                                <button
                                    type="submit"
                                    onClick={handleSubmit}
                                    className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded"
                                >
                                    Aggiungi utente
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Box>
        </Modal>
    );
};

export default UserModal;
