import React from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/16/solid";
import { englishToItalian } from "../../helpers/helpers";

const UserRow = ({ key, user, handleDeleteOpen, setSelectedUser, handleEditOpen, checkedUsers, setCheckedUsers }) => {

    const handleCheckboxChange = (id) => {
        if (checkedUsers.includes(id)) {
            setCheckedUsers(checkedUsers.filter(userId => userId !== id));
        } else {
            setCheckedUsers([...checkedUsers, id]);
        }
    };

    return (
        <>
            <tr>
                <td className="py-3 ps-4">
                <div className="flex items-center h-5">
                    <input 
                        type="checkbox"
                        className="border-gray-200 rounded text-blue-600 focus:ring-blue-500"
                        checked={checkedUsers.includes(user.id)}
                        onChange={() => handleCheckboxChange(user.id)}
                    />
                    <label htmlFor={`hs-table-search-checkbox-${user.id}`} className="sr-only">Checkbox</label>
                            
                </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center font-medium text-gray-800">
                    <div className="flex justify-center">
                        <img className="w-10 h-10 rounded-full" src={user.image_url === null ? `https://ntrepidcorp.com/wp-content/uploads/2016/06/team-1.jpg` : user.image_url} alt="user photo" />
                    </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center font-medium text-gray-800">{user?.first_name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-800">{user?.last_name}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-800">{user?.email}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-800">{englishToItalian(user?.role)}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-center text-gray-800">
                    <div className="flex justify-around items-center">
                        <PencilSquareIcon 
                            onClick={() => {
                                setSelectedUser({
                                     id: user.id,
                                     firstName: user.first_name,
                                     lastName: user.last_name,
                                     email: user.email,
                                     role: englishToItalian(user.role),
                                     image_url: user.image_url,
                                })
                                handleEditOpen()
                            }}
                        className="w-6 h-6 text-[#FFC000] cursor-pointer" />
                        <TrashIcon onClick={() => {
                            setSelectedUser({
                                id: user.id
                            })
                            handleDeleteOpen()
                        }} className="w-6 h-6 text-[#FF0000] cursor-pointer" />
                    </div>
                </td>
            </tr>
        </>
    );
}

export default UserRow;