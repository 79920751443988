const localHost = process.env.NODE_ENV !== "production";

const serverLink = localHost ? "http://127.0.0.1:8000" : "https://api.nubelab.it"

// Is user validated

export const isUserValidated = async (token) => {
  try {
    const res = await fetch(`${serverLink}/api/users/validated`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200)
      return true;
    else
      return false;
  } catch (err) {
    return false;
  }
};

// Login

export const login = async (email, password) => {
  try {
    const res = await fetch(`${serverLink}/api/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    });
    
    if (res.status === 200)
      {
          return {
              valid: true,
              data: res.json(),
          }
      }
      else
      {
          return {
              valid: false,
              data: res.json(),
          }
      }
  } catch (err) {
    return err;
  }
};

// User Management

export const getAllUsers = async () => {
    try {
      const res = await fetch(`${serverLink}/api/users`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200)
      {
        return res.json();
      }
      else
      {
        return false
      }
    } catch (err) {
      return err;
    }
};

export const createUser = async (formData) => {
    try {
      const res = await fetch(`${serverLink}/api/users`, {
        method: "POST",
        headers: {},
        body: formData,
      });
      
      if (res.status === 201)
        {
            return {
                valid: true,
                data: res.json(),
            }
        }
        else
        {
            return {
                valid: false,
                data: res.json(),
            }
        }
    } catch (err) {
      return err;
    }
  };

  export const updateUser = async (id, formData) => {
    try {
      const res = await fetch(`${serverLink}/api/users/update/${id}`, {
        method: "POST",
        headers: {},
        body: formData,
      });
      
      if (res.status === 200)
        {
            return {
                valid: true,
                data: res.json(),
            }
        }
        else
        {
            return {
                valid: false,
                data: res.json(),
            }
        }
    } catch (err) {
      return err;
    }
  };

  export const deleteUser = async (id) => {
    try {
      const res = await fetch(`${serverLink}/api/users/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      if (res.status === 200)
        {
            return {
                valid: true,
                data: res.json(),
            }
        }
        else
        {
            return {
                valid: false,
                data: res.json(),
            }
        }
    } catch (err) {
      return err;
    }
  };

  export const deleteMultipleUsers = async (user_ids) => {
    try {
      const res = await fetch(`${serverLink}/api/users/delete-multiple`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_ids: user_ids }),
      });
      
      if (res.status === 200)
        {
            return {
                valid: true,
            }
        }
        else
        {
            return {
                valid: false,
            }
        }
    } catch (err) {
      return err;
    }
  };


  export const getAllChats = async (category) => {
    try {
      const res = await fetch(`${serverLink}/api/chats/${category}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status === 200)
      {
        return {
          valid: true,
          data: res.json()
        }
      }
      else
      {
        return {
          valid: false,
          data: res.json()
        }
      }
    } catch (err) {
      return err;
    }
};

export const createChat = async (formData) => {
  try {
    const res = await fetch(`${serverLink}/api/chats`, {
      method: "POST",
      headers: {},
      body: formData,
    });
    
    if (res.status === 200)
      {
          return {
              valid: true,
              data: res.json(),
          }
      }
      else
      {
          return {
              valid: false,
              data: res.json(),
          }
      }
  } catch (err) {
    return err;
  }
};